import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SmartSoftTable } from "soft_digi";
import {
  MANAGE_OFFER_IMAGE_URLS,
  MANAGE_OFFER_URLS,
} from "../../../../api/Business/OffersUrls";
import SmartHeader from "../../../../components/site/FormElements/SmartHeader";
import SmartImageDisplay from "../../../../components/site/FormElements/SmartImageDisplay";
import { useSiteContext } from "../../../../contexts/SiteProvider";
import {
  showAlertAutoClose,
  showNotification,
  showYesOrNoAlert,
} from "../../../../services/notifyService";
import { encrypt_data } from "../../../../services/sessionService";
import { get, post } from "../../../../services/smartApiService";

const MyOffersTable = () => {
  const navigate = useNavigate();
  const [tabData, setTabData] = useState([]);
  const { setLoading } = useSiteContext();
  const [category, setCategory] = useState("1");
  const baseNav = "/manage-services";

  const navigateLink = (index) => {
    navigate(baseNav + "/" + index);
  };

  const loadTableData = () => {
    setLoading(true, "Loading....Please Wait");
    const handleError = (errorMessage) => {
      showNotification("error", errorMessage);
      setLoading(false);
    };
    const subscription = get(MANAGE_OFFER_URLS.GET_ALL, handleError).subscribe(
      (response) => {
        setTabData(response.data);
        setLoading(false);
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };

  useEffect(() => {
    loadTableData();
  }, []);

  // const nameImage = () => {
  //   return (
  //     <>
  //       <div className="is-flex">
  //         <figure className=" image is-32x32 mr-2">
  //           <img src={FOLDER_ICON} alt="icon" />
  //         </figure>
  //       </div>
  //     </>
  //   );
  // };

  const offer_image = (data) => {
    let url = MANAGE_OFFER_IMAGE_URLS.GET_ONE_IMAGE;
    let id = data.image_id !== undefined ? data.image_id : 0;
    return (
      <SmartImageDisplay
        srcType="URL"
        url={url}
        id={id}
        // classList={"image-creact-offer"}
        imageClass="is-32x32 "
      />
    );
  };

  const { openModal, closeModal } = useSiteContext();

  const openDeleteModal = (id, name) => {
    let modalObject = {
      title: "Do you Want to Delete?",
      body: "Note: The user will be deleted! Action cannot be reverted",
      okFunction: () => {
        deleteData(id);
      },
      cancelFunction: () => {
        closeModal();
        // console.log("cancel function")
      },
    };
    openModal(modalObject);
  };

  const viewbuttons = [
    {
      label: "",
      leftIcon: "fa fa-eye",
      type: "icon",
      classList: ["smart-edit-icon"],
      onClick: (data) => {
        // console.log("data", data);
        let id =
          data.ID !== undefined
            ? encodeURIComponent(encrypt_data("" + data.ID))
            : "";
        navigateLink("my-offer-view/" + id);
      },
    },
  ];
  const martketbutton = [
    {
      label: "",
      leftIcon: "fa fa-archive",
      type: "icon",
      classList: ["smart-purplemarket-icon"],
      onClick: (data) => {
        // console.log("data", data);
        let id =
          data.ID !== undefined
            ? encodeURIComponent(encrypt_data("" + data.ID))
            : "";
        navigateLink("market-offer-form/" + id);
      },
    },
  ];

  const editbutton = [
    {
      label: "",
      leftIcon: "fa fa-pencil-square-o",
      type: "icon",
      classList: ["smart-editgreen-icon"],
      onClick: (data) => {
        // console.log("data", data);
        let id =
          data.ID !== undefined
            ? encodeURIComponent(encrypt_data("" + data.ID))
            : "";
        navigateLink("create-offers-form/" + id);
      },
    },
  ];

  const Customer_Data = [
    { value: "1", label: "" },
    { value: "2", label: "" },
  ];

  const updateStatus = (itemIn, check_value) => {
    console.log("check_value ", check_value);
    let new_status = itemIn.offer_status == 0 ? 1 : 0;
    let msg =
      new_status == 0
        ? "Do you wish to reactivate the offer?"
        : "Do you wish to inactivate the offer?";
    showYesOrNoAlert(
      msg,
      (selection) => updateStatusFinal(selection, itemIn, new_status),
      "info"
    );
  };
  const updateStatusFinal = (selection, itemIn, new_status) => {
    if (selection == "yes") {
      // post it to backend also
      deleteData(itemIn.ID, new_status);
    }
  };

  const SwitchForm = (item) => {
    return (
      item.ID && (
        <>
          <div className="field">
            <input
              id={"switchRoundedDefault_" + item.ID}
              type="checkbox"
              // name="switchRoundedDefault"
              class="switch is-rounded is-small"
              checked={item.offer_status == 0 ? "checked" : ""}
              onChange={(event) => updateStatus(item, event)}
            />
            <label for={"switchRoundedDefault_" + item.ID}></label>
          </div>
          {/* <SmartSoftCheckRadioSwitch
            options={Customer_Data}
            switchMode={true}
            isRight={true}
            inputProps={{ isRounded: true }}
            value={item.offer_status == 0 ? "1" : ""}
            onChange={(value) => updateStatus(item, value)}
          /> */}
        </>
      )
    );
  };

  // const delete_buttons = [
  //   {
  //     label: "",
  //     leftIcon: "fa-trash",
  //     type: "icon",
  //     classList: ["smart-delete-icon"],
  //     onClick: (data) => {
  //       openDeleteModal(data["ID"]);
  //       //  viewEditData(data["ID"],"VIEW");
  //     },
  //   },
  // ];
  const deleteData = (id, status) => {
    debugger
    setLoading(true, "Updating....Please Wait");
    const handleError = (errorMessage) => {
      // showNotification("error", errorMessage);
      setLoading(false);
    };
    const subscription = post(
      MANAGE_OFFER_URLS.DELETE_PRODUCT,
      { id: id, status: status },
      handleError
    ).subscribe((response) => {
      setLoading(false);
      setTabData((prevItems) =>
        prevItems.map((item) =>
          item.ID === id
            ? { ...item, offer_status: status } // Ensure quantity is not less than 1
            : item
        )
      );
      if(status == 0)
        showAlertAutoClose("Offer Reactivated", "success");
      else
      showAlertAutoClose("Offer Inactivated", "success");
    });
    return () => {
      subscription.unsubscribe();
    };
  };

  const earnFunction=(data)=>{
     let total_price = data.total_price ? parseFloat(data.total_price) : 0;
     let earnFraction = data.earnFraction ? parseFloat(data.earnFraction) : 0;
     return parseInt(total_price * earnFraction);
  }

  const columns = [
    { title: "S.No.", index: "sno", type: "sno" },
    {
      title: "Image",
      index: "img",

      valueFunction: offer_image,
    },
    { title: "Offer Title", index: "offer_title", isSortable: true ,width:"25"},
    // { title: "Qty", index: "qty", isSortable: true },
    { title: "Price (A$)", index: "price", isSortable: true },
    { title: "Discount %", index: "discount" },
    { title: "Sale Price (A$)", index: "total_price" },
    { title: "CROPs", index: "earn_crop_points", valueFunction:earnFunction },
    {
      title: "View",
      index: "view",
      type: "buttons",
      buttons: viewbuttons,
    },
    {
      title: "Market",
      index: "martket",
      type: "buttons",
      buttons: martketbutton,
    },
    {
      title: "Amend",
      index: "amend",
      type: "buttons",
      buttons: editbutton,
    },
    {
      title: "Action",
      index: "amend",
      valueFunction: SwitchForm,
    },
    // {
    //   title: "Remove",
    //   index: "remove",
    //   type: "buttons",
    //   buttons: delete_buttons,
    // },
  ];

  return (
    <>
      <div className="smart-admin-crop-container">
        <p className="smart-bussiness-header pb-3 pl-3 pt-4">
          {/* <span className="icon mt-1">
            <i className="fa fa-caret-left" aria-hidden="true"></i>
            <i className="fa fa-caret-left dim-icon" aria-hidden="true"></i>
          </span> */}
          {/* <span className="ml-5">My Offers</span> */}
          <div className="pl-1">
            <SmartHeader title={"Manage Services"} sub_title={"My Offers"} />
          </div>
        </p>
        <div classNmae="columns">
          <div className="column">
            <SmartSoftTable
              data={tabData}
              columns={columns}
              tableProps={{ className: "crop-bussiness-table-layout" }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default MyOffersTable;
